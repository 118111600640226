import { gql } from '@apollo/client';
export const pagedListingSearchQuery1 = {
  query: gql`
   query Listings($type: CardType!, $searchTerm: String $hasSold: Boolean, $offset: Int, $isCurrentUser: Boolean, $userId: String, $filter: [SolrFilter], $randomSortKey: String) {
  listings(type: $type, searchTerm: $searchTerm  hasSold: $hasSold, offset: $offset, isCurrentUser: $isCurrentUser, userId: $userId, filter: $filter, randomSortKey: $randomSortKey) {
        numFound
        start
        listings {
          id
          heading
          subHeading
          footer
          footer2
          cardInfo
          cardInfo2
          cardInfo3
          cardInfo4
          cardInfo5
          cardInfo6
          cardInfo7
          cardInfo8
          userId
          cardId
          weaknessType
          resistType
          evolutionStage
          imageSrc
          illustrator
          buyItNowPrice
          series
          set
          releaseDate
          condition
          rarity
          isTracked
          title
          year
          number
          name
          imageList {
            name
          }
          shippingType
          internationalShippingCharge
          domesticShippingCharge
          sellerAvatar
          paymentUserName
          purchaseDate
          searchTags
          listingType
          emailAddress
          country
          ts
      }
    }
  } 
`,
  variables: {
    type: 'POKEMON',
    hasSold: false,
    isCurrentUser: false,
    filter: []
  }
};

