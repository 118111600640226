/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListingStatusEnum } from '../../../models/create-listing/iCardListing';
import { IListing } from '../../../models/create-listing/iListing';
import { ISubgrade } from '../../../models/create-listing/ISubgrade';
import { Grade } from '../../../models/create-listing/Grade';
import { ICardGrade } from '../../../models/create-listing/ICardGrade';
import { IShipping } from '../../../models/create-listing/IShipping';
import { ICard } from '../models/iCard';

import { ListingType } from '../../../components/tiles/Tiles';
import { IImage } from '../ImageUploadWithPreview';
import { ITransactionDetails } from '../CardTransaction';
import { getSession, StorageKey } from '../../../stores/Storage';
import { Slices } from '../../../stores/slices';
import { ConditionType } from '../models/ConditionType';

export interface ICreateListingState {
  listingType: ListingType;
  searchCardResults: ICard[];
  selectedCards: ICard[];
  cardSearchInProgress: boolean;
  currentListing: IListing;
  recaptchaToken?: string;
  listingError?: any;
  isLoading: boolean;
}

const intitialGrade = () => ({
  grade: {
    company: '',
    name: '',
    title: '',
    value: 0,
    label: '',
    description: '',
    active: false,
    selected: false,
    children: [],
    subgrades: {} as ISubgrade,
  } as Grade,

  hasSubgrades: 'false',
  selfAssessment: undefined,
});

const sessionListing = getSession<IListing>(StorageKey.createListing);

const initialCreateListingState: ICreateListingState = {
  isLoading: false,
  searchCardResults: [],
  cardSearchInProgress: false,
  selectedCards: [],
  listingType: ListingType.unknown,
  currentListing: sessionListing?.listingStatus
    ? sessionListing
    : {
      title: '',
      listingStatus: ListingStatusEnum.Draft,
      graded: ConditionType.Unknown,
      grade: intitialGrade(),
    } as IListing
};


export const createListingSlice = createSlice({
  name: Slices.createListing,
  initialState: initialCreateListingState,
  reducers: {
    setRecapToken: (state, action: PayloadAction<string>) => {
      state.currentListing.recaptchaToken = action.payload;
    },
    setListingTags: (state, action: PayloadAction<string[]>) => {
      state.currentListing.tags = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    initialiseCurrentListing: (state) => {
      state.currentListing = initialCreateListingState.currentListing;
    },
    setListingTitle(state, action: PayloadAction<string>) {
      state.currentListing.title = action.payload;
    },
    setCanBePaid: (state, action: PayloadAction<boolean>) => {
      state.currentListing.canBePaid = action.payload;
    },
    setCurrentListingStep: (state, action: PayloadAction<string>) => {
      state.currentListing.currentStep = action.payload;
    },
    setCurrentListing: (state, action: PayloadAction<IListing | undefined>) => {
      state.currentListing = action.payload || {} as IListing;
    },
    setListingType: (state, action: PayloadAction<ListingType>) => {
      state.currentListing.listingType = action.payload;
    },
    setCardGradingCompany: (state, action: PayloadAction<string>) => {
      state.currentListing.grade = intitialGrade();
      state.currentListing.grade.grade.company = action.payload;
    },
    setCardCondition: (state, action: PayloadAction<ConditionType>) => {
      state.currentListing.grade = intitialGrade();
      state.currentListing.graded = action.payload;
    },
    setCardHasSubGrades: (state, action: PayloadAction<string>) => {
      if (!state.currentListing.grade)
        state.currentListing.grade = intitialGrade();

      state.currentListing.grade.hasSubgrades = action.payload;
    },
    setCreateListingFeeTable: (state, action: PayloadAction<any>) => {
      state.currentListing.feeTable = action.payload;
    },
    setCardSubgrades: (state, action: PayloadAction<ISubgrade | undefined>) => {
      if (!state.currentListing.grade)
        state.currentListing.grade = intitialGrade();


      state.currentListing.grade.grade.subgrades = action.payload;
    },
    setCard: (state, action: PayloadAction<ICard>) => {
      state.currentListing.card = action.payload;
    },
    clearCurrentListing: (state) => {
      state.currentListing = {} as IListing;
    },

    clearCardGrade: (state) => {
      state.currentListing.grade = intitialGrade();
    },
    clearCardSubgrades: (state) => {
      if (state.currentListing.grade?.grade)
        state.currentListing.grade.grade.subgrades = undefined;
    },
    onClearCardGrade: (state) => {
      state.currentListing.grade = {
        grade: {} as Grade,
      } as ICardGrade;
    },
    setCardGrade: (state, action: PayloadAction<Grade>) => {
      if (!state.currentListing.grade)
        state.currentListing.grade = intitialGrade();

      if (!state.currentListing?.grade?.grade)
        state.currentListing.grade.grade = {} as Grade;


      state.currentListing.grade.grade = action.payload;
    },
    setOtherGradingCompany: (state, action: PayloadAction<string>) => {
      if (!state.currentListing.grade)
        state.currentListing.grade = intitialGrade();

      state.currentListing.grade.otherGradingCompany = action.payload;
    },

    setGrade: (state, action: PayloadAction<Grade>) => {
      if (!state.currentListing.grade)
        state.currentListing.grade = intitialGrade();

      if (!state.currentListing.grade.grade)
        state.currentListing.grade.grade = {} as Grade;

      state.currentListing.grade.grade = action.payload;
    },
    setShipping: (state, action: PayloadAction<IShipping>) => {
      state.currentListing.shipping = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.currentListing.description = action.payload;
    },
    setImages: (state, action: PayloadAction<IImage>) => {
      state.currentListing.images = action.payload;
    },
    setTemporaryImages: (state, action: PayloadAction<IImage>) => {
      state.currentListing.images = action.payload;
    },
    setTransactionDetails: (state, action: PayloadAction<ITransactionDetails>) => {
      state.currentListing.offer = action.payload.offer || '0';
      state.currentListing.buyItNowPrice = action.payload.buyItNowPrice || '0';
    },
    setSelectedCard: (state, PayloadAction: PayloadAction<ICard>) => {
      state.currentListing.card = PayloadAction.payload;
    },
    setListingError: (state, action: PayloadAction<any>) => {
      state.listingError = action.payload;
    },
  }
});

export const {
  setListingError,
  setTemporaryImages,
  setDescription,
  setShipping,
  setTransactionDetails,
  setImages,
  setGrade,
  setCardGradingCompany,
  setCardGrade,
  clearCardSubgrades,
  clearCardGrade,
  setListingType,
  setCard,
  setCurrentListing,
  setCardHasSubGrades,
  setCardSubgrades,
  setCurrentListingStep,
  setSelectedCard,
  initialiseCurrentListing,
  clearCurrentListing,
  setCardCondition,
  setOtherGradingCompany,
  onClearCardGrade,
  setCreateListingFeeTable,
  setCanBePaid,
  setLoadingStatus,
  setListingTitle,
  setListingTags,
  setRecapToken

} = createListingSlice.actions;

export default createListingSlice.reducer;

