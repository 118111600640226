import React from 'react';
// import { Img } from 'src/components/Image';


const remoteUrl = process.env.REACT_APP_REMOTE_ASSETS;

export function RemoteImage(props: { imageName: string, path: string, imgProps?: React.ImgHTMLAttributes<HTMLImageElement>; style?: React.CSSProperties; }) {

    return props.imageName
        ? <img {...props.imgProps}
            style={{ ...props.style }}
            src={`${remoteUrl}/${props.path}/${props.imageName?.toLowerCase().replace(/\s/g, '-').toLowerCase()}.svg`}
            alt="?"
        // fallback="https://dev.midnight-merchant.com/userImages/thumbnails/noPhoto.svg"
        />
        : null;
}
