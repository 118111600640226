import { ReCaptchaAction } from '../../helpers/recaptcha';
import { listing, buyer, other } from '../service-config';
import { deleteAsync, getAsync, getAuthToken, getUser, postAsync } from '../baseService';
import { error } from '../../actions/logActions';
import { IUserRating } from '../../features/user/buyer/buyerActions';
import { IMessage } from '../../components/MessageDialog';
import { ITracking } from 'src/components/TrackingDialog';
import { apolloClient } from '../graph/baseService';
import { pagedListingSearchQuery1 } from 'src/data/gql/queries/pagedListingSearchQuery';

export interface IGetMarketAnalyticsParameters {
    period: keyof typeof listing.analytics;
    cardId: string;
    cardGrade: number;
}

interface IListingServices {
    getListingService: (listingId: string, countryCode?: string) => Promise<any | undefined>;
    getMarketAnalyticsService: (options: IGetMarketAnalyticsParameters) => Promise<any>;
    getMarketAnalyticsSummaryService: (options: IGetMarketAnalyticsParameters) => Promise<any>;
    getPurchaseHistoryService: (buyerID: string) => Promise<any>;
    submitSellerRatingService: (rating: IUserRating) => Promise<any>;
    getSellerListingsService: (page: number, userId: string) => Promise<any>;
    getSellerSoldListingsService: (page: number) => Promise<any>;
    sendMessageService: (message: IMessage) => Promise<any>;
    reportListingService: (listingId: string, reason: string) => Promise<any>;
    reportUserService: (listingId: string, reason: string) => Promise<any>;
    deleteListingService: (listingId: string, countryCode?: string) => Promise<any>;
    setTrackingDetailsService: (trackingDetails: ITracking) => Promise<any>;

}

const services: IListingServices = {
    setTrackingDetailsService: async (trackingDetails: ITracking) => {
        const { listingId, trackingNumber, trackingUrl } = trackingDetails;
        try {
            return await postAsync<any, void>(
                `${listing.setTrackingNumberUrl}?id=${listingId}&trackingNumber=${trackingNumber}&trackingUrl=${trackingUrl}`,
                undefined,
                {},
                ReCaptchaAction.api_listing_search,
                true
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }
    }
    , deleteListingService: async (listingId: string, countryCode = 'UNKNOWN') => {
        try {
            return deleteAsync(listing.deleteListingUrl.replace('{id}', listingId) + '/' + countryCode, undefined, ReCaptchaAction.api_listing_search);
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
        }
    },
    /**
     * @param searchQuery get a listing by listingId
     * @returns 
     */
    getListingService: (listingId: string, countryCode = 'AU') => {
        try {
            return getAsync<any>(
                listing.getListingUrl.replace('{id}', listingId) + '/' + countryCode,
                {},
                ReCaptchaAction.api_listing_search,
                false
            );
        } catch (er: any) {
            // eslint-disable-next-line no-console
            console.error(er);
            error('GET_ERROR', { moduleName: 'getListingService', currentUrl: window.location?.href, errorMessage: er?.message ?? er ?? 'Error getting listing' });
            return Promise.resolve();
        }
    },
    getMarketAnalyticsService: async (options: IGetMarketAnalyticsParameters) => {
        try {

            if (!options.cardId) {
                throw new Error('cardId is required to get market analytics');
            }

            const headers = {
                'x-q-cid': btoa(options.cardId?.toString()),
                'x-q-cgde': btoa(options.cardGrade?.toString()),
            };

            return await getAsync<any>(
                listing.analytics[options.period],
                headers,
                ReCaptchaAction.api_get_analytics_snapshot,
                false
            );
        } catch (er: any) {
            // eslint-disable-next-line no-console
            console.error(er);
            error('GET_MARKET_ANALYTICS_ERROR', { moduleName: 'getMarketAnalyticsService', currentUrl: window.location?.href, errorMessage: er?.message ?? er ?? 'Error getting market analytics' });
            return Promise.resolve();
        }
    }, getMarketAnalyticsSummaryService: (options: IGetMarketAnalyticsParameters) => {
        try {

            if (!options.cardId) {
                throw new Error('cardId is required to get market analytics');
            }

            const headers = {
                'x-q-cid': btoa(options.cardId?.toString()),
                'x-q-cgde': btoa(options.cardGrade?.toString()),
            };

            return getAsync<any>(
                listing.analytics.summary,
                headers,
                ReCaptchaAction.api_get_analytics_summary,
                false
            );
        } catch (er: any) {
            // eslint-disable-next-line no-console
            console.error(er);
            error('GET_MARKET_ANALYTICS_ERROR', { moduleName: 'getMarketAnalyticsService', currentUrl: window.location?.href, errorMessage: er?.message ?? er ?? 'Error getting market analytics summary' });
            return Promise.resolve();
        }
    },
    getPurchaseHistoryService: async (buyerID: string) => {
        try {
            return await postAsync<any, void>(
                buyer.getPurchaseHistoryUrl,
                undefined,
                {
                    'x-q-s': buyerID,
                },
                ReCaptchaAction.api_listing_search
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }
    },
    sendMessageService: async (message: IMessage) => {
        try {
            return await postAsync<any, IMessage>(
                other.sendMessageUrl,
                message,
                {},
                ReCaptchaAction.api_user_rating);
        } catch (ex: any) {
            // eslint-disable-next-line no-console
            console.error(ex);
            error('USER_SEND_MESSAGE_ERROR', { errorMessage: ex.message ?? ex });
            return {
                err: ex ?? 'We are unable to send your message at this time. Please try again later.'
            };
        }
    },
    submitSellerRatingService: async (rating: IUserRating) => {
        try {
            return await postAsync<any, IUserRating>(
                `${buyer.setUserRating}?id=${rating.listingId}`,
                rating,
                {},
                ReCaptchaAction.api_user_rating
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }
    },

    getSellerListingsService: async (page: number, userId: string) => {
        try {
            return await apolloClient.query({
                ...pagedListingSearchQuery1, variables: {
                    type: 'POKEMON',
                    offset: page,
                    hasSold: false,
                    userId: userId,
                    isCurrentUser: false
                }
            });


        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }
    },
    getSellerSoldListingsService: async (page: number) => {
        const token = await getAuthToken();
        const user = await getUser();
        if (!user?.email)
            return { err: 'No user email found' };

        try {
            const results = await apolloClient.query({
                ...pagedListingSearchQuery1, variables: {
                    type: 'POKEMON',
                    offset: page,
                    hasSold: true,
                    isCurrentUser: true,
                    userId: btoa(user?.email)
                },
                context: {
                    headers: {
                        authorization: token
                    }
                }
            });

            return results;

        } catch (er: any) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: 'We are having trouble fetching your sold listings'

            };
        }
    },
    reportListingService: async (listingId: string, reason: string) => {
        try {
            return await postAsync<any, { reason: string; }>(
                listing.reportListingUrl + '?id=' + listingId,
                { reason },
                undefined,

                ReCaptchaAction.api_listing_search
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }

    },

    /**
     * This will be moved to a user Service as we build more functionality around users
     * @param listingId 
     * @param reason 
     */
    reportUserService: async (listingId: string, reason: string) => {
        try {
            return await postAsync<any, { reason: string; }>(
                listing.reportUserUrl + '?id=' + listingId,
                {
                    reason
                },
                undefined,
                ReCaptchaAction.api_listing_search
            );
        } catch (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            return {
                err: er
            };
        }
    },
};

export const {
    getListingService
    , getMarketAnalyticsService
    , getMarketAnalyticsSummaryService
    , getPurchaseHistoryService
    , submitSellerRatingService
    , getSellerListingsService
    , getSellerSoldListingsService
    , sendMessageService
    , reportListingService
    , reportUserService
    , deleteListingService
    , setTrackingDetailsService
} = services;