import { error } from 'src/actions/logActions';
import { getZones } from '../../locales/useLocales';
import { IListing } from '../../models/create-listing/iListing';
import { ISearchResultItem } from '../../models/iSearchResultItem';



export async function mapToSearchResultItem(results: any[], randomSort = true): Promise<ISearchResultItem[]> {
  // const baseUrl = process.env.REACT_APP_IMAGES_URL || '';
  const zone = await getZones();
  try {
    const r = results.map((i: ISearchResultItem) => {
      const item: ISearchResultItem = {
        id: i.id,
        title: i.title,
        heading: i.heading,
        subHeading: i.subHeading,
        footer: i.footer,
        footer2: i.footer2,
        cardInfo: i.cardInfo,
        cardInfo2: i.cardInfo2,
        cardInfo3: i.cardInfo3,
        cardInfo4: i.cardInfo4,
        cardInfo5: i.cardInfo5,
        cardInfo6: i.cardInfo6,
        cardInfo7: i.cardInfo7,
        cardInfo8: i.cardInfo8,
        orderBy: Math.random(),
        userId: i.userId,
        cardId: i.cardId,
        illustrator: i.illustrator,
        imageSrc: Array.isArray(i.imageSrc) ? i.imageSrc : [i.imageSrc],
        price: i.buyItNowPrice ?? 0,
        saleType: i.saleType,
        series: i.series,
        set: i.set,
        releaseDate: i.releaseDate,
        isTracked: i.isTracked,
        grading: i.gradeTitle ?? 0,
        rarity: i.rarity,
        evolutionStage: i.evolutionStage,
        cardType: i.cardType,
        weaknessType: i.weaknessType,
        resistType: i.resistType,
        graded: i.graded,
        language: i.language,
        shipFrom: i.country?.toLowerCase() === zone.name?.toLowerCase() ? 'Domestic' : 'International',
        shippingCost: i.country?.toLowerCase() === zone.name?.toLowerCase() ? i.domesticShippingCharge : i.internationalShippingCharge,
        type1: i.type1,
        type2: i.type2,
        seller: i.emailAddress?.substring(0, i.emailAddress.indexOf('@')) ?? '',
        year: i.releaseDate?.split('/')[2],
        brand: i.brand,
        number: i.number,
        name: i.name,
        shippingType: i.shippingType?.[0],
        domesticShippingCharge: i.domesticShippingCharge || 0,
        internationalShippingCharge: i.internationalShippingCharge ?? 0,
        sellerAvatar: i.sellerAvatar,
        purchaseDate: i.purchaseDate,
        paymentUserName: i.paymentUserName,
        tags: i.searchTags || [],
        listingType: i.listingType,
        locality: i.locality,
        location: i.location,
        country: i.country,
        postCode: i.postCode,
        region: i.region,
        imageList: i.imageList,
        marketAnalysis: i.marketAnalysis,
        marketSummary: i.marketSummary,
        // imageList:
        //   i.imageList &&
        //   i.imageList.map((imag: any) => {
        // const img: CSFile = JSON.parse(imag ?? '{}');
        // img.path = baseUrl + img.path;
        //     return img;
        //   }),
      };
      return item;
    });

    if (randomSort) {
      return r.sort((a, b) => a.orderBy - b.orderBy);
    }

    return r;
  } catch (e) {
    error('MAP_TO_SEARCH_ITEM', { exception: e });
    return [];

  }

}



export async function mapAdvertSearchResults(results: any[]): Promise<IListing[]> {
  const zone = await getZones();

  try {
    return results.map(
      (result: any) =>
      ({
        listingStatus: result['listingStatus'],
        name: result['name'],
        series: result['card.series'],
        set: result['card.set'],
        shipFrom: result.country?.toLowerCase() === zone.name?.toLowerCase() ? 'Domestic' : 'International',
        number: result['card.number'],
        illustrator: result['card.illustrator'],
        rarity: result['card.rarity'],
        elementType: result['card.elementType'],
        price: result['card.buyItNowPrice'],
        userId: result['userId'],
        card: {
          name: result['card.name'],
          number: result['card.number'],
          cardNumberValue: result['card.cardNumberValue'],
          cardNumberValue2: result['card.cardNumberValue2'],
          series: result['card.series'],
          set: result['card.set'],
          rarity: result['card.rarity'],
          elementType: result['card.elementType'],
          cardType: result['card.cardType'],
          releaseDate: result['card.releaseDate'],
          setCode: result['card.setCode'],
          illustrator: result['card.illustrator'],
          abilityDescription1: result['card.abilityDescription1'],
          weaknessType: result['card.weaknessType'],
          weaknessAmount: result['card.weaknessAmount'],
          resistType: result['card.resistType'],
          evolutionStage: result['card.evolutionStage'],
          hitPoints: result['card.hitpoints'],
          imageSrc: result['card.imageSrc'],
          pokemon: result['card.pokemon'],
          resistAmount: result['card.resistAmount'],
          abilityName1: result['card.abilityName1'],
          id: result['card.id'],
          weaknessName: result['card.weaknessName'],
          pokemonTag: result['card.pokemonTag'],
          resistDescription: result['card.resistDescription'],
          selected: false,
        },

        listingType: result['listingType'],
        grade: {
          grade: {
            description: result['grade.description'],
            title: result['grade.title'],
            company: result['grade.company'],
            value: result['grade.value'],
            subgrades: result['grade.subgrades'],
          },
        },

        description: result['description'],
        shipping: result['shipping'],
        buyItNowPrice: result['buyItNowPrice'],
        offer: result['offer'],
        graded: result['graded'],
        images: {},
        sellerAvatar: result.sellerAvatar,
        id: result.id,
        purchaseDate: result.purchaseDate,
        paymentUserName: result.paymentUserName,
        tags: result.searchTags,
        locality: result.locality,
        location: result.location,
        country: result.country,
        postCode: result.postCode,
        region: result.region
      } as IListing)
    );

  } catch (e) {
    error('MAP_TO_SEARCH_ITEM', { exception: e });
    return [];
  }
}
